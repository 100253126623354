// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconBack(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Back";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("g", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("path", {
                                d: "M15.1006181,6.4893944 L15.1255937,6.49597212 L4.41912447,6.49597212 L7.78484732,2.43485411 C7.94966029,2.23658272 8.04006677,1.96799233 8.04006677,1.68608988 C8.04006677,1.40418743 7.94966029,1.13747639 7.78484732,0.938735159 L7.26114011,0.30790012 C7.09645722,0.109628731 6.87701012,0 6.64299391,0 C6.40884762,0 6.18927043,0.108845668 6.02458754,0.307117058 L0.25509286,7.25272359 C0.0897595631,7.45177804 -0.000646921307,7.71692296 0,7.99898202 C-0.000646921307,8.28260721 0.0897595631,8.54790873 0.25509286,8.74664996 L6.02458754,15.6928829 C6.18927043,15.8909977 6.40871754,16 6.64299391,16 C6.87701012,16 7.09645722,15.8908411 7.26114011,15.6928829 L7.78484732,15.0620479 C7.94966029,14.8640897 8.04006677,14.5997279 8.04006677,14.3178254 C8.04006677,14.0360796 7.94966029,13.7856563 7.78484732,13.5875415 L4.38114074,9.50371465 L15.1125856,9.50371465 C15.5947968,9.50371465 16,9.0033378 16,8.42308859 L16,7.53086734 C16,6.95061813 15.5828294,6.4893944 15.1006181,6.4893944 Z"
                              }),
                          fill: Icon.mapColor(props.color),
                          fillRule: "nonzero"
                        }),
                    fill: "none",
                    fillRule: "evenodd",
                    stroke: "none",
                    strokeWidth: "1"
                  })
            });
}

var make = IconBack;

export {
  make ,
}
/* Icon Not a pure module */
